import { onNextRepaint } from '~/shared/utils/animation';
import { InternalTracking } from '~/shared/modules/internal-tracking';
import isFeatureGateEnabled from '~/shared/utils/feature-gates';

export const CONTACT_INFO_REGEX_SCOPE_KEYS = {
  GENERAL: 'general',
  MESSAGE: 'message',
  SCREENSHOT: 'screenshot',
};

/*
  Checks if the given value contains any contact information based on certain regexes.
  The regexes are determined based on the context provided.

  If context is a string, it is used as the key to look up regexes in the
  `Dribbble.JsConfig.contacts.regexes` object.

  If context is an HTMLElement, it looks for the closest ancestor with a
  `data-contact-info-scope` attribute to determine the regex scope to use.

  Allowed context/scope keys are:
  - 'general'
  - 'message'
  - 'screenshot'
*/
export const hasContactInfo = (value = '', context) => {
  let regexScopeKey = CONTACT_INFO_REGEX_SCOPE_KEYS.GENERAL;

  if (typeof context === 'string') {
    regexScopeKey = context;
  } else if (typeof context === 'object' && context instanceof HTMLElement) {
    regexScopeKey = context.closest('[data-contact-info-scope]')?.getAttribute('data-contact-info-scope') || regexScopeKey;
  }

  const scopedRegexes = Dribbble?.JsConfig?.contacts?.regexes?.[regexScopeKey]
    || Dribbble?.JsConfig?.contacts?.regexes?.[CONTACT_INFO_REGEX_SCOPE_KEYS.GENERAL]
    || [];

  let regexes;

  if (isFeatureGateEnabled('disintermediation')) {
    regexes = Object.values(scopedRegexes).map((regex) => new RegExp(regex, 'i')) || [];
  } else {
    regexes = Dribbble?.JsConfig?.engagements?.contact_info_regexes?.map((regex) => new RegExp(regex, 'i')) || [];
  }

  if (!regexes.length) return false;
  return regexes.some((regex) => regex.test(value));
};

export const toggleShakeAnimation = (element, shouldShake) => {
  // Remove shake class if present in order to restart animation
  element.classList.remove('animation-shake');

  // Add animation on next repaint to ensure it replays
  if (shouldShake) {
    onNextRepaint(() => {
      element.classList.add('animation-shake');
    });
  }
};

let hasTracked;

const trackInfoWarningShown = (warningElement) => {
  const payload = {
    location: warningElement.getAttribute('data-contact-info-warning') || '',
    sender_id: warningElement.getAttribute('data-sender-id') || '',
    thread_id: warningElement.getAttribute('data-thread-id') || '',
  };
  InternalTracking.trackContactInfoWarningShown(payload);
};

export const toggleContactInfoWarning = (warningElement, isEnabled) => {
  if (!warningElement) return;
  warningElement.classList.toggle('contact-info-warning--visible', isEnabled);

  if (isEnabled && !hasTracked) {
    trackInfoWarningShown(warningElement);
    hasTracked = true;
  } else if (!isEnabled) {
    hasTracked = false; // reset tracking when warning is removed
  }
};

export const bindContactInfoErrorOnValidation = (form) => {
  if (!form) return;

  // Show contact info error only if the form is validated upon form submission
  // and has contact info. Hide error upon any other validation event.
  form.addEventListener('pristine:form-validated', ({ detail }) => {
    const { isValidatingEntireForm } = detail;
    const contactInfoInputs = Array.from(form.querySelectorAll('[data-pristine-contact-info]'));

    const showContactInfoError = contactInfoInputs.some(
      (input) => hasContactInfo(input.value, input),
    );

    if (isValidatingEntireForm || !showContactInfoError) {
      form.querySelector('[data-contact-info-error]')?.toggleAttribute('hidden', !showContactInfoError);
    }
  });
};
