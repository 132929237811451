import Pristine from '~/globals/form-validation/pristine-modified/pristine';
import { hasContactInfo } from '~/marketplace/contact-info-detection-helpers';
import isFeatureGateEnabled from '~/shared/utils/feature-gates';

// Default pristine validation configuration
// https://github.com/sha256/Pristine
const defaultPristineConfig = {
  // selector class of the parent element where the target `errorClass` below will be added
  classTo: 'js-validation-parent',
  // class that gets assigned to the parent element when the field has an error
  errorClass: 'has-validation-error',

  // selector class of the parent element where error text element is appended
  errorTextParent: 'js-validation-parent',
  // class of the error text element
  errorTextClass: 'validation-error',
};

/*
  A custom global validator to ensure inputs do not contain any contact information.
  To use, add `data-pristine-contact-info` to the field you want to validate and pass in an optional custom error message

  <input data-pristine-contact-info="my custom error message">
*/

// eslint-disable-next-line func-names, prefer-arrow-callback
Pristine.addValidator('contact-info', function (value) {
  // Skip validation if feature flag is disabled
  if (!isFeatureGateEnabled('disintermediation')) return true;
  return !hasContactInfo(value, this);
},
(_value, _params, input) => {
  const learnMoreLink = '<a href="https://support.dribbble.com/hc/en-us/articles/30314539301911" style="--link-color: #ea4c89; --link-decoration: underline" target="_blank">Learn more.</a>';

  // Pristine doesn't allow us to set default error messages for custom validators through HTML attributes, so instead this method checks for a custom message first and then falls back to a default;
  const defaultMessage = 'Contact details or external links aren’t allowed in this field. LEARN_MORE_LINK';
  const customMessage = input.getAttribute('data-pristine-contact-info');

  const message = customMessage || defaultMessage;
  return message.replace('LEARN_MORE_LINK', learnMoreLink);
}, 10, false);

/*
  Helper method to initialize Pristine form validation with our default configuration.

  ```
    import { PristineValidation } from '~/globals/form-validation/form-validation';
    const pristine = PristineValidation(formElement);
  ```
*/

export const PristineValidation = (form, config = {}, live = true) => {
  const pristineConfig = { ...defaultPristineConfig, ...config };
  const pristine = new Pristine(form, pristineConfig, live);

  return pristine;
};
